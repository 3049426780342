<template>
  <div>
    <v-dialog v-model="editDialog" width="500">
      <v-card class="mx-auto">
        <v-card-title>
          <div class="d-flex justify-center">
            <h3>Edit</h3>
          </div>
          <v-spacer></v-spacer>
          <v-btn @click="editDialog = false" icon
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <CoorForm
            button-label="Update"
            @add="updateCoordinates"
            ref="editForm"
            :value="editData"
            :key="editIndex"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <h3 class="d-flex justify-center">GPS Coordinates To GeoJSON</h3>

    <CoorForm @add="addCoordinates" ref="form" />

    <v-simple-table class="mt-5">
      <thead>
        <tr>
          <th class="font-weight-black">Latitude</th>
          <th class="font-weight-black">Longitude</th>
          <th class="font-weight-black">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(coor, index) in coordinates" :key="index">
          <td>
            {{ coor.lat }}
          </td>
          <td>
            {{ coor.lng }}
          </td>
          <td>
            <v-icon color="primary" @click="showEdit(index)">mdi-pencil</v-icon>
            <v-icon @click="deleteCoordinate(index)" color="error"
              >mdi-trash-can</v-icon
            >
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-alert type="error" v-if="errorMsg">
      {{ errorMsg }}
    </v-alert>
    <div class="d-flex justify-center">
      <v-btn color="primary" class="text-capitalize mt-5" @click="exportGeoJSON"
        >Export<v-icon class="pl-2">mdi-file-download-outline</v-icon></v-btn
      >
    </div>
  </div>
</template>

<script>
let coordinates;

function downloadObjectAsJson(exportObj, exportName) {
  const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(
    JSON.stringify(exportObj),
  )}`;
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', dataStr);
  downloadAnchorNode.setAttribute('download', `${exportName}.json`);
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

try {
  coordinates = JSON.parse(localStorage.getItem('coordinates'));
} catch {
  // abort quitely
}

export default {
  components: {
    CoorForm: () => import('./CoorForm.vue'),
  },
  data() {
    return {
      coordinates: coordinates || [],
      editData: {
        lng: '',
        lat: '',
      },
      editIndex: -1,
      editDialog: false,
      errorMsg: '',
    };
  },
  methods: {
    deleteCoordinate(index) {
      this.coordinates.splice(index, 1);
    },
    addCoordinates(value) {
      this.coordinates.push(value);
      this.$refs.form.reset();
    },
    showEdit(index) {
      this.editData = { ...this.coordinates[index] };
      this.editIndex = index;
      this.editDialog = true;
    },
    updateCoordinates(value) {
      this.coordinates[this.editIndex] = value;
      this.editDialog = false;
    },
    saveCoordinates(value) {
      localStorage.setItem('coordinates', JSON.stringify(value));
    },
    exportGeoJSON() {
      if (this.coordinates.length < 3) {
        this.errorMsg = 'Minimum 3 Coordinates Required To Create Polygon';
        setTimeout(() => {
          this.errorMsg = '';
        }, 4000);
        return;
      }
      const result = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Polygon',
          coordinates: [[]],
        },
      };
      this.coordinates.forEach((coor) => {
        const data = [coor.lng, coor.lat];
        result.geometry.coordinates[0].push(data);
      });
      result.geometry.coordinates[0].push(result.geometry.coordinates[0][0]);
      downloadObjectAsJson(
        {
          type: 'FeatureCollection',
          features: [result],
        },
        'geojson',
      );
    },
  },
  watch: {
    coordinates: {
      handler: 'saveCoordinates',
    },
  },
};
</script>

<style>
</style>
