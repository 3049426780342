<template>
  <div>
    <div>
      <v-card
        elevation="19"
        max-width="600px"
        class="mx-auto mt-10 rounded-br-xl rounded-tl-xl"
      >
        <v-card-text>
          <coor-to-geo-json />
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import CoorToGeoJson from '../components/CoorToGeoJson.vue';

export default {
  components: { CoorToGeoJson },
  data() {
    return {};
  },
};
</script>

<style>
</style>
